/** 
 * web端接口配置抽取，通过url参数env控制
 *
 */
module.exports = {
  development: {
    //测试
    push2: '//push2.eastmoney.com/',
    lk: "//roadshow-qas.lvb.eastmoney.com/LVB/",  // lvb2 临时测试
    cfh: '//caifuhaoapi-zptest.eastmoney.com/api/v1/webchannel/',
    guba: "//gbapi-test.eastmoney.com/",
    lkbanner: "//roadshow-qas.lvb.eastmoney.com/LVB/"
  },
  test: {
    //测试
    push2: '//push2.eastmoney.com/',
    cfh: '//caifuhaoapi-zptest.eastmoney.com/api/v1/webchannel/',
    guba: "//gbapi-test.eastmoney.com/",
    lk: "//roadshow-qas.lvb.eastmoney.com/LVB/",
    lkbanner: "//roadshow-qas.lvb.eastmoney.com/LVB/"
  },
  production: {
    push2: '//push2.eastmoney.com/',
    cfh: "//caifuhaoapi.eastmoney.com/api/v1/webchannel/",
    guba: "//gbapi.eastmoney.com/",
    lk: "//roadshow.lvb.eastmoney.com/LVB/",
    lkbanner: "//roadshow.lvb.eastmoney.com/LVB/"
  },
  /**
   * 获取参数
   * @param {*} name 
   */
  getParam: function (name) {
    // 加层缓存
    if (this.cacheParam) { return this.cacheParam };

    var urlpara = location.search;
    var par = {};
    if (urlpara != "") {
      urlpara = urlpara.substring(1, urlpara.length);
      var para = urlpara.split("&");
      var parname;
      var parvalue;
      for (var i = 0; i < para.length; i++) {
        parname = para[i].substring(0, para[i].indexOf("="));
        parvalue = para[i].substring(para[i].indexOf("=") + 1, para[i].length);
        par[parname] = parvalue;
      }
    }
    if (typeof (par[name]) != "undefined") {
      this.cacheParam = par[name];
      return par[name];
    }
    else {
      return null;
    }
  },
  /**
   * 获取环境变量
   */
  WEB_ENV: function () {
    if (this.cacheWebEnv) { return this.cacheWebEnv }
    var env = "production";
    if (self.location.hostname != "roadshow.eastmoney.com") {
      env = "test";
    }
    if (this.getParam('env') && this[this.getParam('env')]) {
      env = this.getParam('env');
    } else if (typeof webEnv != "undefined" && this[webEnv]) {
      env = webEnv;
    }
    this.cacheWebEnv = env;
    return env
  },
  getWebPath: function (name) {
    var env = this.WEB_ENV();
    env = "production";
    return this[env][name]
  }
}

